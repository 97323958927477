import request from '@/utils/request'

// 查询票根用户列表
export function listTicketStubUser(query) {
  return request({
    url: '/ticket/stub/user/list',
    method: 'get',
    params: query
  })
}

// 查询票根用户详细
export function getTicketStubUser(id) {
  return request({
    url: '/ticket/stub/user/' + id,
    method: 'get'
  })
}

// 新增票根用户
export function addTicketStubUser(data) {
  return request({
    url: '/ticket/stub/user',
    method: 'post',
    data: data
  })
}

// 修改票根用户
export function updateTicketStubUser(data) {
  return request({
    url: '/ticket/stub/user',
    method: 'put',
    data: data
  })
}

// 删除票根用户
export function delTicketStubUser(id) {
  return request({
    url: '/ticket/stub/user/' + id,
    method: 'delete'
  })
}
