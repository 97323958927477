<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <!--      <a-form-model-item label="用户id 外键user_user.id" prop="userId" >-->
      <!--        <a-input v-model="form.userId" placeholder="请输入用户id 外键user_user.id" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="用户名称" prop="userName" >
        <a-input v-model="form.userName" placeholder="请输入用户名称" :disabled="true"/>
      </a-form-model-item>
      <!--      <a-form-model-item label="票根id 外键ticket_stub.id" prop="ticketId" >-->
      <!--        <a-input v-model="form.ticketId" placeholder="请输入票根id 外键ticket_stub.id" />-->
      <!--      </a-form-model-item>-->
      <a-form-model-item label="票根名" prop="ticketName" >
        <a-input v-model="form.ticketName" placeholder="请输入票根名" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="填写店铺名称" prop="fillShopName" >
        <a-input v-model="form.fillShopName" placeholder="请输入填写店铺名称" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="填写日期" prop="fillDate" >
        <a-input v-model="form.fillDate" placeholder="请输入填写日期" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="填写时间" prop="fillTime" >
        <a-input v-model="form.fillTime" placeholder="请输入填写时间" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="填写角色" prop="fillRole" >
        <a-input v-model="form.fillRole" placeholder="请输入填写角色" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="票根唯一编码" prop="ticketSerialNumber" >
        <a-input v-model="form.ticketSerialNumber" placeholder="请输入票根唯一编码" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="自定义图片" prop="diyImage" >
        <img :src="form.diyImage" alt="" style="width: 100px" />
      </a-form-model-item>
      <a-form-model-item label="剩余交易次数" prop="remainingTransNumber" >
        <a-input v-model="form.remainingTransNumber" placeholder="请输入剩余交易次数" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="票根转赠时间" prop="transferTime" >
        <a-date-picker style="width: 100%" v-model="form.transferTime" format="YYYY-MM-DD HH:mm:ss" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="使用状态" prop="status" >
        <a-select style="width: 100%" v-model="form.status" placeholder="请选择状态">
          <a-select-option v-for="(item, index) in this.customDict.TicketStubUserStatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTicketStubUser, addTicketStubUser, updateTicketStubUser } from '@/api/ticket/ticketStubUser'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        userId: null,
        ticketId: null,
        fillShopName: null,
        fillDate: null,
        fillTime: null,
        fillRole: null,
        ticketSerialNumber: null,
        diyImage: null,
        remainingTransNumber: null,
        transferTime: null,
        status: 1,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        // userId: [
        //   { required: true, message: '用户id 外键user_user.id不能为空', trigger: 'blur' }
        // ],
        // ticketId: [
        //   { required: true, message: '票根id 外键ticket_stub.id不能为空', trigger: 'blur' }
        // ],
        status: [
          { required: true, message: '使用状态 0.未激活 1.已激活 2.已转赠不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        ticketId: null,
        fillShopName: null,
        fillDate: null,
        fillTime: null,
        fillRole: null,
        ticketSerialNumber: null,
        diyImage: null,
        remainingTransNumber: null,
        transferTime: null,
        status: 1,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTicketStubUser(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTicketStubUser(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTicketStubUser(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
